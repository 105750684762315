import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import '../styles/about.scss'
import Layout from '../components/Layout/Layout'
import { Helmet } from 'react-helmet'

const About = ({ data }) => {
  return (
    <Layout title="About College Pulse">
      <Helmet>
        <title>College Pulse | About Us</title>
        <meta property="og:image" content="/cp-website-min.jpg" />
      </Helmet>
      <main className="about-container">
        <section className="has-background-primary has-padding-6 is-centered methodology-title">
          <h1 className="title has-text-white has-text-weight-bold has-margin-top-4 has-margin-bottom-3 ">
            ABOUT US
          </h1>
        </section>

        <section className="container has-margin-top-6 has-margin-bottom-6 has-padding-top-4 has-padding-bottom-4">
          <p className="subtitle has-text-weight-light has-text-centered desc is-5">
            At College Pulse, we believe there is a better way to measure public opinion. A more
            accurate, less invasive way where data is shared openly rather than extracted from
            individuals. We know that students' time and thoughts are worth more than just a gift
            card. That’s why we focus on providing students with access to insights about what their
            community thinks on topics they care about. Insights they can't find anywhere else. This
            student-first approach has allowed us to grow into the largest and most reliable source
            of college student opinion.
          </p>
        </section>

        <section className="container has-padding-bottom-5">
          <div className="columns has-padding-bottom-5">
            <div className="column">
              <h1 className="title has-text-weight-bold">Our founding</h1>
              <p>
                In the wake of a student protest at Dartmouth College in 2016, the campus felt
                utterly divided. Students tried to make sense of what had taken place, what they
                believed, and whether their opinions aligned with those of the larger community.
                With no reliable source of community opinion available, administrators and students
                alike turned to polarizing social media platforms that instilled distorted
                perceptions of community opinion. Inspired by these events, our founders, Terren
                Klein and Robin Jayaswal, set out to create a student-centered opinion platform to
                fill this informational void.
              </p>
            </div>
            <div className="column">
              <Img fluid={data.imageOne.childImageSharp.fluid} />
            </div>
          </div>

          <div className="columns has-padding-bottom-5">
            <div className="column">
              <Img fluid={data.imageTwo.childImageSharp.fluid} />
            </div>
            <div className="column">
              <h1 className="title has-text-weight-bold">Early growth</h1>
              <p>
                After launching the app at Dartmouth in 2017, we saw rapid adoption. In the first
                month, 60% of the student body signed up. By the end of the school year, we had
                collected over 2 million responses from 95% of the student body.
              </p>
            </div>
          </div>

          <div className="columns has-padding-bottom-5">
            <div className="column">
              <h1 className="title has-text-weight-bold">Expansion & Funding</h1>
              <p>
                Propelled by investments from top investors including Madrona Ventures, GSV
                Accelerate, and Y-Combinator, we began our nationwide university expansion and
                experienced remarkable growth. By the start of 2019, we had collected over 20
                million responses from 125,000 college students representing more than 180 four-year
                colleges and universities in all 50 states.
              </p>
            </div>
            <div className="column">
              <Img fluid={data.imageThree.childImageSharp.fluid} />
            </div>
          </div>

          <div className="columns has-padding-bottom-5">
            <div className="column">
              <Img fluid={data.imageFour.childImageSharp.fluid} />
            </div>
            <div className="column">
              <h1 className="title has-text-weight-bold">To infinity and beyond</h1>
              <p>
                We believe people have a right to access accurate data about what their communities
                think, and this goes beyond college campuses. Distorted perceptions of public
                opinion is a major impediment for progress in communities of all sizes and we see
                that as an opportunity: We’re excited to build the largest and most reliable source
                of public opinion, and uncover the truth about what the world thinks.
              </p>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const aboutQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "about/1.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageTwo: file(relativePath: { eq: "about/2.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageThree: file(relativePath: { eq: "about/3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageFour: file(relativePath: { eq: "about/4.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default About
